import React from 'react'
import SignIn from './pages/signin'
import SignUp from './pages/signup'
import Dashboard from './pages/dashboard'
import ResetPassword from './pages/reset-password'
import ImpliedVolatilityGraph from './pages/impliedvolatilitygraph'

import { RequireAuth } from './features/auth/RequireAuth'

import { store } from './app/store'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout } from './components/Layout'
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            {/* <Route path="/sign-up" element={<SignUp />} /> */}
            <Route element={<RequireAuth />}>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/implied-volatility-graph" element={<ImpliedVolatilityGraph />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App