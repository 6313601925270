import { useEffect, useRef, useState } from 'react'
import { SelectBox } from 'devextreme-react/select-box';
import { ProductApiService } from '../../utils/productApiService';
import DateBox from 'devextreme-react/date-box';
import { formatDate_YYYYMMDD, qty_format } from '../../utils/common';
import { Button } from 'devextreme-react/button';
import LineChart from '../../components/Chart';
import { logOut } from '../../features/auth/authSlice';
import { useNavigate, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import SpeedoMeter from '../../components/SpeedoMeter';

function ImpliedVolatilityGraph() {
  const menuicon = useRef()
  const arrow = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch() 
  
  const handleLogout = () => {
    dispatch(logOut({ ...{ access: null }, user: null }))
    navigate('/')
  }

  const [datasource, setDataSource] = useState(null)
  const [selected_security_id, setSelectedSecurityId] = useState(null)
  const [selected_yyyymmdd, setSelectedYYYYMMDD] = useState(formatDate_YYYYMMDD(new Date()))
  const [impliedVolatilities, setImpliedVolatilities] = useState(null)
  const [show_modal, setShowModal] = useState(true)

  const now = new Date();
  const dateLabel = { 'aria-label': 'Date' };
  const securitylabel = { 'aria-label': 'Security' };

  const showmenu = (e) => {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("close");
  }
  const show_subitems = (e) => {
    let arrowParent = e.target.parentElement.parentElement; //selecting main parent of arrow
    arrowParent.classList.toggle("showMenu");
  }

  const ApiService = new ProductApiService()
  useEffect(() => {
    ApiService.get_securityid_list().then((response) => {
      setDataSource(
        {
          store: {
            type: 'array',
            data: response,
            key: 'SecurityId',
          },
          group: 'groupname',
        }
      )
    })
  }, [])


  const onSelectValueChanged =(e) =>{
    setSelectedSecurityId(e.value)
  }

  const onDateValueChanged =(e) =>{
    setSelectedYYYYMMDD(formatDate_YYYYMMDD(e.value))
  }

  const onSubmit = (e)=>{
    ApiService.get_historical_impliedVolatilities(selected_yyyymmdd,selected_security_id)
    .then((response) => {
      setImpliedVolatilities(response)
    })
  }

  const handleClose = () => setShowModal(false);
  return (
    <>
      <div className="sidebar close">
        <div className="logo-details">
          <i className="bx" ><img style={{ height: "30px" }} src="/assets/images/plusplus.png" alt="" /></i>
          <span className="logo_name">PlusPlus Research</span>
        </div>
        <ul className="nav-links">
          <li>
            <a href="/implied-volatility-graph">
              <i className="bx bx-line-chart"></i>
              <span className="link_name">Implied Volatility Graph</span>
            </a>
            <ul className="sub-menu blank">
              <li><a className="link_name" href="/implied-volatility-graph">Implied Volatility Graph</a></li>
            </ul>
          </li>
          {/* <li>
            <a href="/dashboard">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="link_name">Dashboard</span>
            </a>
            <ul className="sub-menu blank">
              <li><a className="link_name" href="/dashboard">Dashboard</a></li>
            </ul>
          </li>           */}
        </ul>
      </div>
      <section className="home-section">
      <span className="bx home-content" style={{float:"right", marginRight:"20px",cursor:"pointer"}} onClick={(e)=> handleLogout()}>Logout</span>
        <div className="home-content">
          <i className="bx bx-menu" onClick={(e) => showmenu(e)}></i>
          <span className="text" ref={menuicon} >Implied Volatility Graph</span>
          
        </div>
        <div className="bx dx-fieldset width-850">
        <div className="dx-field">
          <div className="dx-field-label">Instrument</div>
          <div className="dx-field-value">
          <SelectBox
                searchEnabled={true}
                dataSource={datasource}
                valueExpr="SecurityId"
                inputAttr={securitylabel}
                grouped={true}
                onValueChanged={onSelectValueChanged}
                displayExpr="Description" />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Date</div>
          <div className="dx-field-value">
          <DateBox
                width={300}
                defaultValue={now}
                inputAttr={dateLabel}
                type="date"
                onValueChanged={onDateValueChanged}
              />
          </div>
        </div>   
        <div className="dx-field">
          <div className="dx-field-label"></div>
          <div className="dx-field-value">
          <Button
                width={220}
                text="Submit"
                type="default"
                stylingMode="contained"
                onClick={onSubmit}
              />
          </div>
        </div>   
        {impliedVolatilities===false&&
        <div style={{textAlign:"center"}}>
          <h4 className='bx'>There is no data for the selected date. Please select another date and try again.</h4>
        </div>
        }                       
        </div>
        <br />
        {impliedVolatilities&&impliedVolatilities.chart.length > 0 && impliedVolatilities.total.totalCallOpenInt > 0 && impliedVolatilities.total.totalPutOpenInt > 0 &&
        <div style={{marginLeft:"20px",marginTop:"50px",width:"1300px",textAlign:"center"}}>
          <LineChart datasource={impliedVolatilities.chart} title={`${impliedVolatilities.total.description} - ${selected_yyyymmdd}`}
          subtitle={`Total Call : ${qty_format(impliedVolatilities.total.totalCallOpenInt)} Total Put: ${qty_format(impliedVolatilities.total.totalPutOpenInt)}`}
          />
        <br />
        </div>
        }       
      </section>
      <Modal 
        show={show_modal} 
        onHide={handleClose}         
        backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>PlusPlus Research</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>This application is for informational purposes only and should not be used to make investment decisions.<br />
        Data comes from reputable sources and is presumed to be accurate but not guaranteed as such.<br />
        Past performance is not reflective of future performance.</p>        
        </Modal.Body>
        <Modal.Footer>
          <Button stylingMode="contained" type="default" onClick={handleClose}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>   
    </>
  )
}

export default ImpliedVolatilityGraph