import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { SitemarkIcon } from './CustomIcons';
import AppTheme from '../../shared-theme/AppTheme';

import { useRef, useState, useEffect, Navigate } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ProductApiService } from '../../utils/productApiService';
import CircularProgress from '@mui/material/CircularProgress';
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

export default function ResetPassword(props) {
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [password2Error, setPassword2Error] = useState(false);
  const [password2ErrorMessage, setPassword2ErrorMessage] = useState('');

  const ApiService = new ProductApiService()
  const [loading, SetLoading] = React.useState(false)

  const handleSubmit = async (event) => {
    let is_Valid = validateInputs()
    if (!is_Valid) {
      return;
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    if (token === null){
      setPasswordError(true);
      setPasswordErrorMessage('Token parameter is not found!');      
    }
    if (passwordError || password2Error) {
      return;
    }
    const password = document.getElementById('password');
    const password2 = document.getElementById('password2');
    let data  = {password:password.value,password2:password2.value,token:token}
    SetLoading(true)
    ApiService.reset_password(data).then((response) => {
      SetLoading(false)
    }).catch((response) => {
      console.log(response)
    })     
  };

  const validateInputs = () => {
    const password = document.getElementById('password');
    const password2 = document.getElementById('password2');

    let isValid = true;
    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
      return isValid;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }
    if (!password2.value || password2.value.length < 6) {
      setPassword2Error(true);
      setPassword2ErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
      return isValid;
    } else if(password.value !== password2.value) {
      setPassword2Error(true);
      setPassword2ErrorMessage('Passwords do not match.');
      isValid = false; 
      return isValid;     
    }
    else {
      setPasswordError(false);
      setPasswordErrorMessage('');
      setPassword2Error(false);
      setPassword2ErrorMessage('');      
    }    

    return isValid;
  };

  return (
    <AppTheme {...props}>
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
            
          <SitemarkIcon />
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Reset Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                required
                fullWidth
                variant="outlined"
                color={passwordError ? 'error' : 'primary'}               
              />
            </FormControl>
            <FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormLabel htmlFor="password2">Password Again</FormLabel>
              </Box>
              <TextField
                error={password2Error}
                helperText={password2ErrorMessage}
                name="password2"
                placeholder="••••••"
                type="password"
                id="password2"
                autoComplete="current-password"
                required
                fullWidth
                variant="outlined"
                color={password2Error ? 'error' : 'primary'}
                onKeyUp={(e) =>{if (e.key === 'Enter') {handleSubmit(e)}} }
              />
            </FormControl>
            <Button
              type="button"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
            >
              <CircularProgress size="10px" style={{ display: loading ? '' : 'none' }} />
              Reset
            </Button>
          </Box>
        </Card>
      </SignInContainer>
    </AppTheme>
  );
}