import React from 'react'
import ReactSpeedometer from "react-d3-speedometer"
import { useEffect } from 'react'
function SpeedoMeter(props) {
    // console.log(props.value)
    return (
        <ReactSpeedometer
            width={400}
            needleHeightRatio={0.9}
            minValue={0}
            maxValue={100}
            value={props.value * 100}
            textColor='#525652'
            currentValueText={"Percentile Ranking " + props.value * 100 + "%"}
            segmentColors={['#db5b5b', '#fda751', '#e2cf5c', '#92d050', '#109547']}
            customSegmentLabels={[
                {
                    text: "VERY LOW",
                    position: "INSIDE",
                    color: "#fff",

                },
                {
                    text: "LOW",
                    position: "INSIDE",
                    color: "#fff",
                },
                {
                    text: "AVERAGE",
                    position: "INSIDE",
                    color: "#fff",
                },
                {
                    text: "HIGH",
                    position: "INSIDE",
                    color: "#fff",

                },
                {
                    text: "VERY HIGH",
                    position: "INSIDE",
                    color: "#fff",

                },
            ]}
        />

    )
}

export default SpeedoMeter