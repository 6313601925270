import { useEffect, useRef, useState } from 'react'
import { SelectBox } from 'devextreme-react/select-box';
import { ProductApiService } from '../../utils/productApiService';
import DateBox from 'devextreme-react/date-box';
import { formatDate_YYYYMMDD, qty_format } from '../../utils/common';
import { Button } from 'devextreme-react/button';
import LineChart from '../../components/Chart';
import { logOut } from '../../features/auth/authSlice';
import { useNavigate, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import SpeedoMeter from '../../components/SpeedoMeter';

function Dashboard() {
  const menuicon = useRef()
  const arrow = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch() 
  
  const handleLogout = () => {
    dispatch(logOut({ ...{ access: null }, user: null }))
    navigate('/')
  }

  const [show_modal, setShowModal] = useState(true)
  const [emini, setEmini] = useState(0)
  const [tenYrNote, setTenYrNote] = useState(0)
  const [crudeOil, setCrudeOil] = useState(0)
  const [gold, setGold] = useState(0)
  const [euro, setEuro] = useState(0)
  const [naturalGas, setNaturalGas] = useState(0)
  const [yyyymmdd, setYYYYMMDD] = useState("")

  const showmenu = (e) => {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("close");
  }
  const show_subitems = (e) => {
    let arrowParent = e.target.parentElement.parentElement; //selecting main parent of arrow
    arrowParent.classList.toggle("showMenu");
  }

  const ApiService = new ProductApiService()
  useEffect(() => {
    ApiService.get_insrument_percent_rank("91").then((response) => {
      setEmini(response.percentile)
      setYYYYMMDD(response.yyyymmdd)
    })
    ApiService.get_insrument_percent_rank("12").then((response) => {
      setTenYrNote(response.percentile)
    })
    ApiService.get_insrument_percent_rank("40").then((response) => {
      setCrudeOil(response.percentile)
    })
    ApiService.get_insrument_percent_rank("50").then((response) => {
      setGold(response.percentile)
    })
    ApiService.get_insrument_percent_rank("83").then((response) => {
      setEuro(response.percentile)
    })
    ApiService.get_insrument_percent_rank("43").then((response) => {
      setNaturalGas(response.percentile)
    })
  }, [])



  const handleClose = () => setShowModal(false);
  return (
    <>
      <div className="sidebar close">
        <div className="logo-details">
          <i className="bx" ><img style={{ height: "30px" }} src="/assets/images/plusplus.png" alt="" /></i>
          <span className="logo_name">PlusPlus Research</span>
        </div>
        <ul className="nav-links">
        <li>
            <a href="/implied-volatility-graph">
              <i className="bx bx-line-chart"></i>
              <span className="link_name">Implied Volatility Graph</span>
            </a>
            <ul className="sub-menu blank">
              <li><a className="link_name" href="/implied-volatility-graph">Implied Volatility Graph</a></li>
            </ul>
          </li>
          <li>
            <a href="/dashboard">
              <i className="bx bxs-doughnut-chart"></i>
              <span className="link_name">Dashboard</span>
            </a>
            <ul className="sub-menu blank">
              <li><a className="link_name" href="/dashboard">Dashboard</a></li>
            </ul>
          </li> 
        </ul>
      </div>
      <section className="home-section">
      <span className="bx home-content" style={{float:"right", marginRight:"20px",cursor:"pointer"}} onClick={(e)=> handleLogout()}>Logout</span>
        <div className="home-content">
          {/* <i className="bx bx-menu" onClick={(e) => showmenu(e)}></i> */}
          <span className="text" ref={menuicon} >{"Percentile Ranking of ATM implied volatility on " + yyyymmdd}</span>
          
        </div>
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-4">
              <div className="card-transparent">
                <div className="card-body" style={{textAlign: "center"}}>
                  <span>E mini S&P 500</span>
                  <SpeedoMeter value={emini}/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-transparent" >
                <div className="card-body" style={{textAlign: "center"}}>
                <span>10Yr Note</span>
                <SpeedoMeter value={tenYrNote}/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-transparent">
                <div className="card-body" style={{textAlign: "center"}}>
                <span>Crude Oil</span>
                <SpeedoMeter value={crudeOil}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="card-transparent">
                <div className="card-body" style={{textAlign: "center"}}>
                <span>Gold</span>
                <SpeedoMeter value={gold}/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-transparent">
                <div className="card-body" style={{textAlign: "center"}}>
                <span>Euro</span>
                <SpeedoMeter value={euro}/>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-transparent">
                <div className="card-body" style={{textAlign: "center"}}>
                <span>Natural Gas</span>
                <SpeedoMeter value={naturalGas}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal 
        show={show_modal} 
        onHide={handleClose}         
        backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title>PlusPlus Research</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>This application is for informational purposes only and should not be used to make investment decisions.<br />
        Data comes from reputable sources and is presumed to be accurate but not guaranteed as such.<br />
        Past performance is not reflective of future performance.</p>        
        </Modal.Body>
        <Modal.Footer>
          <Button stylingMode="contained" type="default" onClick={handleClose}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>   
    </>
  )
}

export default Dashboard